<template>
  <div :style="backCss">
    <div class="statistics-menu-list">
      <a-dropdown
        v-for="(item, index) in columns"
        :trigger="['click']"
        :ref="'statistics-' + index"
        :key="index"
        :style="item.isNum ? '' : 'display: none;'"
        :placement="'topCenter'"
      >
        <div class="statistics">统计<a-icon type="caret-up" /></div>
        <a-menu slot="overlay">
          <a-menu-item v-if="item.isNum" @click="compute('meanNum', item.dataIndex, index, '求和')">
            求和
          </a-menu-item>
          <a-menu-item v-if="item.isNum" @click="compute('sumNum', item.dataIndex, index, '平均')">
            平均
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <a-spin :spinning="tableLoading">
      <a-dropdown v-model="visible" :placement="'bottomRight'" :trigger="['contextmenu']">
        <a-menu slot="overlay">
          <a-menu-item v-for="(item, index) in columns" :key="item.dataIndex">
            <a-checkbox v-model="item.checked" @change="checkChange(index)">
              {{ item.title }}
            </a-checkbox>
          </a-menu-item>
        </a-menu>
        <!-- @filter-change="filterChangeEvent" -->
        <vxe-table
          border="none"
          ref="xTree"
          :row-config="{ isCurrent: true, isHover: true }"
          :column-config="{ resizable: true }"
          @checkbox-change="selectChangeEvent"
          @checkbox-all="selectChangeEvent"
          @cell-click="cellClickEvent"
          @filter-visible="filterVisible"
          @toggle-tree-expand="toggleExpandChangeEvent"
          :footer-method="footerMethod"
          :tree-config="{
            hasChild: 'hasChild',
            parentField: 'parentId',
            rowField: 'teamId',
            expandRowKeys: ['A05A02', 'A05A01'],
            toggleMethod: loadChildrenMethod
          }"
          :filter-config="{
            remote: true,
            iconNone: 'iconfont icon-shaixuan icon',
            iconMatch: 'iconfont icon-shaixuan icon filter-product-btn'
          }"
          @filter-change="filterChangeEvent"
          :data="tableData"
          show-footer
        >
          <vxe-column align="center" fixed="left" type="checkbox" minWidth="80" show-overflow> </vxe-column>

          <vxe-column
            v-for="record in copyColumns"
            :key="record.dataIndex"
            :fixed="record.dataIndex == 'team' ? 'left' : ''"
            :field="record.dataIndex"
            :title="record.title"
            :minWidth="record.width"
            :tree-node="record.dataIndex == 'team'"
            show-overflow
            :filters="
              record.dataIndex == 'contractType' ||
              record.dataIndex == 'userFile.workDays' ||
              record.dataIndex == 'userFile.goodAtProductionType' ||
              record.dataIndex == 'userFile.badAtProductionType' ||
              record.dataIndex == 'enabled'
                ? []
                : null
            "
          >
            <template v-if="record.dataIndex == 'team'" v-slot="{ row }">
              <div
                v-if="!row.hasChild"
                class="nick-name-div"
                @click.stop="toOtherCalender(row)"
                style="display:flex;align-items:center"
              >
                <div class="status-img">
                  <img v-if="row.avatar" :src="row.avatar" style="width:35px;height:35px;border-radius: 50%;" alt="" />
                  <img v-else src="./../../assets/tx.png" style="width:35px;height:35px;border-radius: 50%;" alt="" />
                  <div
                    class="status"
                    :style="
                      row.onlineType == 1
                        ? 'background-color:#24B300'
                        : row.onlineType == 2
                        ? 'background-color:gray'
                        : 'background-color:#ADADAD'
                    "
                  ></div>
                </div>
                <span>{{ row.name }}</span>
              </div>
              <div v-else>
                {{ row.team }}
                <span>{{ row.children.length && row.isPostion ? '（' + row.children.length + '）' : '' }}</span>
              </div>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.workDays'" v-slot="{ row }">
              <template v-if="!row.hasChild">
                <a-tag
                  v-for="one in Array.from(row.workDays || []).sort((a, b) => {
                    return a - b
                  })"
                  :key="one"
                >
                  {{
                    one == '1'
                      ? '周一'
                      : one == '2'
                      ? '周二'
                      : one == '3'
                      ? '周三'
                      : one == '4'
                      ? '周四'
                      : one == '5'
                      ? '周五'
                      : one == '6'
                      ? '周六'
                      : one == '7'
                      ? '周日'
                      : one == '-1'
                      ? '跳过中国法定节假日'
                      : ''
                  }}
                </a-tag>
              </template>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.qq'" v-slot="{ row }">
              <span v-if="!row.hasChild">
                <div v-if="row.qq">
                  <a-icon type="qq" style="width:1vw;color:hsl(0deg 0% 75%);" />
                  <span style="margin-left:3px">{{ row.qq }}</span>
                </div>
                <div v-if="row.wechat">
                  <a-icon type="wechat" style="width:1vw;color:hsl(0deg 0% 75%);" />
                  <span style="margin-left:3px">{{ row.wechat }}</span>
                </div>
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.goodAtProductionType'" v-slot="{ row }">
              <span>
                <template v-if="!row.hasChild && row.goodAtProductionType">
                  <a-tag v-for="one in row.goodAtProductionType" :key="one">
                    {{ one == '其他' ? (row.skilledProductOther ? row.skilledProductOther : one) : one }}
                  </a-tag>
                </template>
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.badAtProductionType'" v-slot="{ row }">
              <span>
                <template v-if="!row.hasChild && row.badAtProductionType">
                  <a-tag v-for="one in row.badAtProductionType" :key="one">
                    {{ one == '其他' ? (row.unskilledProductOther ? row.unskilledProductOther : one) : one }}
                  </a-tag>
                </template>
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.workCity'" v-slot="{ row }">
              <span>
                <div>{{ !row.hasChild ? getWorkCity(row.workAddress) : '' }}</div>
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'weekExpect'" v-slot="{ row }">
              <span>
                {{ row.weekExpect == '-1.1' || row.weekExpect == -1.1 ? '-' : row.weekExpect }}
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'weekFree'" v-slot="{ row }">
              <span>
                {{ row.weekFree == '-1.1' || row.weekFree == -1.1 ? '-' : row.weekFree }}
              </span>
            </template>
            <template v-else-if="record.dataIndex == 'enabled'" v-slot="{ row }">
              <div v-if="!row.hasChild">
                <a-icon v-if="row.enabled" type="close-circle" />
                <a-icon v-else type="check-circle" />
                <span style="margin-left:4px;">{{ row.enabled ? '停用' : '启用' }}</span>
              </div>
            </template>
            <template v-if="record.dataIndex == 'contractType'" #filter="{ $panel, column }">
              <a-spin
                :spinning="filterLoading"
                style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
              >
                <a-tree
                  v-model="checkedContractTypeKeys"
                  :defaultExpandedKeys="defaultOpenKeys"
                  @expand="treeExpand"
                  @select="
                    (selectedKeys, info) => {
                      treeSelect(record.dataIndex, selectedKeys, info)
                    }
                  "
                  checkable
                  :tree-data="treeData"
                />
              </a-spin>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.workDays'" #filter="{ $panel, column }">
              <a-spin
                :spinning="filterLoading"
                style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
              >
                <a-tree
                  v-model="checkedWorkDaysKeys"
                  :defaultExpandedKeys="defaultOpenKeys"
                  @expand="treeExpand"
                  @select="
                    (selectedKeys, info) => {
                      treeSelect(record.dataIndex, selectedKeys, info)
                    }
                  "
                  checkable
                  :tree-data="treeData"
                />
              </a-spin>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.goodAtProductionType'" #filter="{ $panel, column }">
              <a-spin
                :spinning="filterLoading"
                style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
              >
                <a-tree
                  v-model="checkedGoodAtProductionTypeKeys"
                  :defaultExpandedKeys="defaultOpenKeys"
                  @expand="treeExpand"
                  @select="
                    (selectedKeys, info) => {
                      treeSelect(record.dataIndex, selectedKeys, info)
                    }
                  "
                  checkable
                  :tree-data="treeData"
                />
              </a-spin>
            </template>
            <template v-else-if="record.dataIndex == 'userFile.badAtProductionType'" #filter="{ $panel, column }">
              <a-spin
                :spinning="filterLoading"
                style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
              >
                <a-tree
                  v-model="checkedBadAtProductionTypeKeys"
                  :defaultExpandedKeys="defaultOpenKeys"
                  @expand="treeExpand"
                  @select="
                    (selectedKeys, info) => {
                      treeSelect(record.dataIndex, selectedKeys, info)
                    }
                  "
                  checkable
                  :tree-data="treeData"
                />
              </a-spin>
            </template>
            <template v-else-if="record.dataIndex == 'enabled'" #filter="{ $panel, column }">
              <a-spin
                :spinning="filterLoading"
                style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
              >
                <a-tree
                  v-model="checkedEnabledKeys"
                  :defaultExpandedKeys="defaultOpenKeys"
                  @expand="treeExpand"
                  @select="
                    (selectedKeys, info) => {
                      treeSelect(record.dataIndex, selectedKeys, info)
                    }
                  "
                  checkable
                  :tree-data="treeData"
                />
              </a-spin>
            </template>
          </vxe-column>
        </vxe-table>
      </a-dropdown>
    </a-spin>
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import { citys } from '../v-table/modules/city.js'
import { simpleDebounce } from '@/utils/util'
import moment from 'moment'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
export default {
  props: {
    queryParamProp: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      propertys: [],
      visible: false,
      columns: [
        {
          title: '部门-昵称',
          width: 200,
          scopedSlots: { customRender: 'team' },
          dataIndex: 'team',
          checked: true,
          isNum: false
        },
        /*         {
          title: '昵称',
          dataIndex: 'nickName',
          scopedSlots: { customRender: 'nickName' },
          width: 160,
          sorter: true,
          checked: true,
          isNum: false
        }, */
        /*         {
          title: '所属部门',
          width: 200,
          scopedSlots: { customRender: 'departs' },
          dataIndex: 'departs',
          checked: true,
          isNum: false
        },
        {
          title: '职位',
          width: 160,
          scopedSlots: { customRender: 'positions' },
          dataIndex: 'positions',
          checked: true,
          isNum: false
        }, */
        /* {
          title: '在线状态',
          width: 80,
          scopedSlots: { customRender: 'onlineType' },
          dataIndex: 'onlineType',
        }, */
        {
          title: '签约类型',
          width: 120,
          dataIndex: 'contractType',
          scopedSlots: { customRender: 'contractType' },
          checked: true,
          isNum: false
        },
        {
          title: '聊天工具',
          width: 160,
          dataIndex: 'userFile.qq',
          scopedSlots: { customRender: 'qq' },
          checked: true,
          isNum: false
        },
        {
          title: '工作地',
          width: 160,
          scopedSlots: { customRender: 'workCity' },
          dataIndex: 'userFile.workCity',
          checked: true,
          isNum: false
        },
        /*         {
          title: '签约类型',
          width: 120,
          dataIndex: 'userFile.contractType',
          checked: true,
          isNum: false
        }, */
        {
          title: '可工作日',
          width: 180,
          dataIndex: 'userFile.workDays',
          scopedSlots: { customRender: 'workDays' },
          checked: true,
          isNum: false
        },
        {
          title: '周可安排数量',
          width: 120,
          dataIndex: 'weekAvailableCount',
          scopedSlots: { customRender: 'weekAvailableCount' },
          checked: true,
          isNum: true
        },
        {
          title: '周已安排数量',
          width: 120,
          dataIndex: 'weekAccept',
          scopedSlots: { customRender: 'weekAccept' },
          checked: true,
          isNum: true
        },
        {
          title: '周空余数量',
          width: 110,
          dataIndex: 'weekFree',
          scopedSlots: { customRender: 'weekFree' },
          checked: true,
          isNum: true
        },
        {
          title: '擅长作品类型',
          width: 180,
          scopedSlots: { customRender: 'goodAtProductionType' },
          dataIndex: 'userFile.goodAtProductionType',
          checked: true,
          isNum: false
        },
        {
          title: '抵触作品类型',
          width: 180,
          scopedSlots: { customRender: 'badAtProductionType' },
          dataIndex: 'userFile.badAtProductionType',
          checked: true,
          isNum: false
        },
        {
          title: '账号状态',
          width: 100,
          scopedSlots: { customRender: 'enabled' },
          dataIndex: 'enabled',
          checked: true,
          isNum: false
        }
        /*         {
          title: '工作台账号',
          width: 100,
          dataIndex: 'onlyTool',
          scopedSlots: { customRender: 'onlyTool' },
        }, */
      ],
      copyColumns: [
        {
          title: '部门-昵称',
          width: 200,
          scopedSlots: { customRender: 'team' },
          dataIndex: 'team',
          checked: true,
          isNum: false
        },
        /*         {
          title: '昵称',
          dataIndex: 'nickName',
          scopedSlots: { customRender: 'nickName' },
          width: 160,
          sorter: true,
          checked: true,
          isNum: false
        }, */
        /*         {
          title: '所属部门',
          width: 200,
          scopedSlots: { customRender: 'departs' },
          dataIndex: 'departs',
          checked: true,
          isNum: false
        },
        {
          title: '职位',
          width: 160,
          scopedSlots: { customRender: 'positions' },
          dataIndex: 'positions',
          checked: true,
          isNum: false
        }, */
        /* {
          title: '在线状态',
          width: 80,
          scopedSlots: { customRender: 'onlineType' },
          dataIndex: 'onlineType',
        }, */
        {
          title: '签约类型',
          width: 120,
          dataIndex: 'contractType',
          scopedSlots: { customRender: 'contractType' },
          checked: true,
          isNum: false
        },
        {
          title: '聊天工具',
          width: 160,
          dataIndex: 'userFile.qq',
          scopedSlots: { customRender: 'qq' },
          checked: true,
          isNum: false
        },
        {
          title: '工作地',
          width: 160,
          scopedSlots: { customRender: 'workCity' },
          dataIndex: 'userFile.workCity',
          checked: true,
          isNum: false
        },
        /*         {
          title: '签约类型',
          width: 120,
          dataIndex: 'userFile.contractType',
          checked: true,
          isNum: false
        }, */
        {
          title: '可工作日',
          width: 180,
          dataIndex: 'userFile.workDays',
          scopedSlots: { customRender: 'workDays' },
          checked: true,
          isNum: false
        },
        {
          title: '周可安排数量',
          width: 120,
          dataIndex: 'weekExpect',
          scopedSlots: { customRender: 'weekAvailableCount' },
          checked: true,
          isNum: true
        },
        {
          title: '周已安排数量',
          width: 120,
          dataIndex: 'weekAccept',
          scopedSlots: { customRender: 'weekAccept' },
          checked: true,
          isNum: true
        },
        {
          title: '周空余数量',
          width: 110,
          dataIndex: 'weekFree',
          scopedSlots: { customRender: 'weekFree' },
          checked: true,
          isNum: true
        },
        {
          title: '擅长作品类型',
          width: 180,
          scopedSlots: { customRender: 'goodAtProductionType' },
          dataIndex: 'userFile.goodAtProductionType',
          checked: true,
          isNum: false
        },
        {
          title: '抵触作品类型',
          width: 180,
          scopedSlots: { customRender: 'badAtProductionType' },
          dataIndex: 'userFile.badAtProductionType',
          checked: true,
          isNum: false
        },
        {
          title: '账号状态',
          width: 100,
          scopedSlots: { customRender: 'enabled' },
          dataIndex: 'enabled',
          checked: true,
          isNum: false
        }
        /*         {
          title: '工作台账号',
          width: 100,
          dataIndex: 'onlyTool',
          scopedSlots: { customRender: 'onlyTool' },
        }, */
      ],
      tableData: [],
      selectRows: [],
      queryParmas: {
        current: 1,
        size: 50
      },
      color: '',
      productName: '',
      tableLoading: false,
      filterLoading: false,
      selectedTreeKeys: [],
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: '/sys/user/list',
        delete: '/sys/user/delete',
        deleteBatch: '/sys/user/deleteBatch',
        exportXlsUrl: '/sys/user/exportXls',
        importExcelUrl: 'sys/user/importExcel'
      },
      treeData: [],
      contractTypeMap: {
        全职: '0',
        兼职: '1',
        意向全职: '2',
        实习: '3',
        暂无签约类型: '4'
      },
      defaultOpenKeys: [],
      selectedTreeKeys: [],
      allTableData: [],
      checkedContractTypeKeys: [],
      checkedWorkDaysKeys: [],
      checkedGoodAtProductionTypeKeys: [],
      checkedBadAtProductionTypeKeys: [],
      checkedEnabledKeys: [],
      isAdmin: false
    }
  },
  created() {
    this.loadData()
    const userInfo = Vue.ls.get(USER_INFO)
    this.isAdmin = userInfo.roles.some(item => {
      return item.roleCode == 'admin'
    })
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
            localStorage.setItem(
              'color',
              JSON.stringify({ hoverColor: this.color, color: this.$store.state.app.color })
            )
          }
        }
      }
    }, 50)
    this.$nextTick(() => {
      let copyColumns = JSON.parse(JSON.stringify(this.copyColumns)).filter(item => {
        return (
          item.dataIndex == 'contractType' ||
          item.dataIndex == 'userFile.workDays' ||
          item.dataIndex == 'userFile.goodAtProductionType' ||
          item.dataIndex == 'userFile.badAtProductionType' ||
          item.dataIndex == 'enabled'
        )
      })
      Array.from(document.getElementsByClassName('vxe-filter--btn')).forEach((item, index) => {
        if (copyColumns[index].dataIndex == 'userFile.workDays') {
          copyColumns[index].dataIndex = 'workDays'
        } else if (copyColumns[index].dataIndex == 'userFile.goodAtProductionType') {
          copyColumns[index].dataIndex = 'goodAtProductionType'
        } else if (copyColumns[index].dataIndex == 'userFile.badAtProductionType') {
          copyColumns[index].dataIndex = 'badAtProductionType'
        }
        item.className = item.className + ' filter-' + copyColumns[index].dataIndex + '-btn'
      })
    })
  },
  computed: {
    backCss() {
      let colorBack = this.color
      let color = this.$store.state.app.color
      let contractTypeColor = this.checkedContractTypeKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let workDaysColor = this.checkedWorkDaysKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let goodAtProductionTypeColor = this.checkedGoodAtProductionTypeKeys.length
        ? this.$store.state.app.color
        : 'rgba(0,0,0,0.65)'
      let badAtProductionTypeColor = this.checkedBadAtProductionTypeKeys.length
        ? this.$store.state.app.color
        : 'rgba(0,0,0,0.65)'
      let enabledColor = this.checkedEnabledKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      return {
        '--back-color': colorBack,
        '--theme-color': color,
        '--contractType-color': contractTypeColor,
        '--workDays-color': workDaysColor,
        '--goodAtProductionType-color': goodAtProductionTypeColor,
        '--badAtProductionType-color': badAtProductionTypeColor,
        '--enabled-color': enabledColor
      }
    }
  },
  watch: {
    queryParamProp: {
      deep: true, //true为进行深度监听,false为不进行深度监听
      handler(newVal) {
        if (!newVal.nickName) {
          this.tableData = JSON.parse(JSON.stringify(this.allTableData))
        }
      }
    }
  },
  methods: {
    moment,
    async filterVisible(e) {
      if (e.visible) {
        switch (e.property) {
          case 'contractType':
            this.treeData = [
              {
                title: '全职',
                value: '全职',
                key: '全职'
              },
              {
                title: '兼职',
                value: '兼职',
                key: '兼职'
              },
              {
                title: '意向全职',
                value: '意向全职',
                key: '意向全职'
              },
              {
                title: '实习',
                value: '实习',
                key: '实习'
              }
            ]
            break
          case 'enabled':
            this.treeData = [
              {
                title: '启用',
                value: '启用',
                key: '启用'
              },
              {
                title: '停用',
                value: '停用',
                key: '停用'
              }
            ]
            break
          case 'userFile.goodAtProductionType':
            this.treeData = [
              { value: '门派', title: '门派', key: '门派' },
              { value: '武侠', title: '武侠', key: '武侠' },
              { value: '玄幻', title: '玄幻', key: '玄幻' },
              { value: '奇幻', title: '奇幻', key: '奇幻' },
              { value: '异能', title: '异能', key: '异能' },
              { value: '仙侠', title: '仙侠', key: '仙侠' },
              { value: '复仇', title: '复仇', key: '复仇' },
              { value: '动作', title: '动作', key: '动作' },
              { value: '恋爱', title: '恋爱', key: '恋爱' },
              { value: '霸凌', title: '霸凌', key: '霸凌' },
              { value: '惊悚', title: '惊悚', key: '惊悚' },
              { value: '丧尸', title: '丧尸', key: '丧尸' },
              { value: '竞技', title: '竞技', key: '竞技' },
              { value: '冒险', title: '冒险', key: '冒险' },
              { value: 'AI', title: 'AI', key: 'AI' },
              { value: '搞笑', title: '搞笑', key: '搞笑' },
              { value: '豪门霸总', title: '豪门霸总', key: '豪门霸总' },
              { value: '都市', title: '都市', key: '都市' },
              { value: '契约', title: '契约', key: '契约' },
              { value: '养成', title: '养成', key: '养成' },
              { value: '治愈', title: '治愈', key: '治愈' },
              { value: '悬疑', title: '悬疑', key: '悬疑' },
              { value: '料理', title: '料理', key: '料理' },
              { value: '妖怪', title: '妖怪', key: '妖怪' },
              { value: '精灵', title: '精灵', key: '精灵' },
              { value: '医学', title: '医学', key: '医学' },
              { value: '科幻', title: '科幻', key: '科幻' },
              { value: '军事', title: '军事', key: '军事' },
              { value: '同人', title: '同人', key: '同人' },
              { value: '热血', title: '热血', key: '热血' },
              { value: '暗黑', title: '暗黑', key: '暗黑' },
              { value: '美食', title: '美食', key: '美食' },
              { value: '逆袭', title: '逆袭', key: '逆袭' },
              { value: '励志', title: '励志', key: '励志' },
              { value: '伦理', title: '伦理', key: '伦理' },
              { value: '性转', title: '性转', key: '性转' },
              { value: '宫斗', title: '宫斗', key: '宫斗' },
              { value: '烧脑', title: '烧脑', key: '烧脑' },
              { value: '其他', title: '其他', key: '其他' }
            ]
            break
          case 'userFile.badAtProductionType':
            this.treeData = [
              { value: '门派', title: '门派', key: '门派' },
              { value: '武侠', title: '武侠', key: '武侠' },
              { value: '玄幻', title: '玄幻', key: '玄幻' },
              { value: '奇幻', title: '奇幻', key: '奇幻' },
              { value: '异能', title: '异能', key: '异能' },
              { value: '仙侠', title: '仙侠', key: '仙侠' },
              { value: '复仇', title: '复仇', key: '复仇' },
              { value: '动作', title: '动作', key: '动作' },
              { value: '恋爱', title: '恋爱', key: '恋爱' },
              { value: '霸凌', title: '霸凌', key: '霸凌' },
              { value: '惊悚', title: '惊悚', key: '惊悚' },
              { value: '丧尸', title: '丧尸', key: '丧尸' },
              { value: '竞技', title: '竞技', key: '竞技' },
              { value: '冒险', title: '冒险', key: '冒险' },
              { value: 'AI', title: 'AI', key: 'AI' },
              { value: '搞笑', title: '搞笑', key: '搞笑' },
              { value: '豪门霸总', title: '豪门霸总', key: '豪门霸总' },
              { value: '都市', title: '都市', key: '都市' },
              { value: '契约', title: '契约', key: '契约' },
              { value: '养成', title: '养成', key: '养成' },
              { value: '治愈', title: '治愈', key: '治愈' },
              { value: '悬疑', title: '悬疑', key: '悬疑' },
              { value: '料理', title: '料理', key: '料理' },
              { value: '妖怪', title: '妖怪', key: '妖怪' },
              { value: '精灵', title: '精灵', key: '精灵' },
              { value: '医学', title: '医学', key: '医学' },
              { value: '科幻', title: '科幻', key: '科幻' },
              { value: '军事', title: '军事', key: '军事' },
              { value: '同人', title: '同人', key: '同人' },
              { value: '热血', title: '热血', key: '热血' },
              { value: '暗黑', title: '暗黑', key: '暗黑' },
              { value: '美食', title: '美食', key: '美食' },
              { value: '逆袭', title: '逆袭', key: '逆袭' },
              { value: '励志', title: '励志', key: '励志' },
              { value: '伦理', title: '伦理', key: '伦理' },
              { value: '性转', title: '性转', key: '性转' },
              { value: '宫斗', title: '宫斗', key: '宫斗' },
              { value: '烧脑', title: '烧脑', key: '烧脑' },
              { value: '其他', title: '其他', key: '其他' }
            ]
            break
          case 'userFile.workDays':
            this.treeData = [
              {
                title: '周一',
                value: '1',
                key: '1'
              },
              {
                title: '周二',
                value: '2',
                key: '2'
              },
              {
                title: '周三',
                value: '3',
                key: '3'
              },
              {
                title: '周四',
                value: '4',
                key: '4'
              },
              {
                title: '周五',
                value: '5',
                key: '5'
              },
              {
                title: '周六',
                value: '6',
                key: '6'
              },
              {
                title: '周日',
                value: '7',
                key: '7'
              },
              {
                title: '跳过中国法定节假日',
                value: '-1',
                key: '-1'
              }
            ]
        }
      }
    },
    treeSelect(type, selectedKeys, info) {
      console.log(type, selectedKeys, info)

      if (type == 'contractType') {
        this.checkedContractTypeKeys = selectedKeys
      }
      if (type == 'userFile.workDays') {
        this.checkedWorkDaysKeys = selectedKeys
      }
      if (type == 'userFile.goodAtProductionType') {
        this.checkedGoodAtProductionTypeKeys = selectedKeys
      }
      if (type == 'userFile.badAtProductionType') {
        this.checkedBadAtProductionTypeKeys = selectedKeys
      }
      if (type == 'enabled') {
        this.checkedEnabledKeys = selectedKeys
      }
      this.selectedTreeKeys = selectedKeys
      this.$refs.xTree.closeFilter()
      let filterArr = {
        contractType: [],
        workDays: [],
        goodAtProductionType: [],
        badAtProductionType: [],
        enabled: []
      }
      if (this.checkedContractTypeKeys.length) {
        filterArr.contractType = this.checkedContractTypeKeys
      }
      if (this.checkedWorkDaysKeys.length) {
        filterArr.workDays = this.checkedWorkDaysKeys
      }
      if (this.checkedGoodAtProductionTypeKeys.length) {
        filterArr.goodAtProductionType = this.checkedGoodAtProductionTypeKeys
      }
      if (this.checkedBadAtProductionTypeKeys.length) {
        filterArr.badAtProductionType = this.checkedBadAtProductionTypeKeys
      }
      if (this.checkedEnabledKeys.length) {
        filterArr.enabled = this.checkedEnabledKeys
      }
      this.filterQuery(filterArr)
    },
    treeExpand(keys) {
      this.defaultOpenKeys = keys
    },
    async filterChangeEvent({ column, $event, values, filters }) {
      console.log(column, filters)
      if ($event.target.innerText == '筛选') {
        let filterArr = {
          contractType: [],
          workDays: [],
          goodAtProductionType: [],
          badAtProductionType: [],
          enabled: []
        }
        if (this.checkedContractTypeKeys.length) {
          filterArr.contractType = this.checkedContractTypeKeys
        }
        if (this.checkedWorkDaysKeys.length) {
          filterArr.workDays = this.checkedWorkDaysKeys
        }
        if (this.checkedGoodAtProductionTypeKeys.length) {
          filterArr.goodAtProductionType = this.checkedGoodAtProductionTypeKeys
        }
        if (this.checkedBadAtProductionTypeKeys.length) {
          filterArr.badAtProductionType = this.checkedBadAtProductionTypeKeys
        }
        if (this.checkedEnabledKeys.length) {
          filterArr.enabled = this.checkedEnabledKeys
        }
        if (
          !this.checkedContractTypeKeys.length &&
          !this.checkedWorkDaysKeys.length &&
          !this.checkedGoodAtProductionTypeKeys.length &&
          !this.checkedBadAtProductionTypeKeys.length &&
          !this.checkedEnabledKeys.length
        ) {
          this.loadData()
          return
        }
        this.filterQuery(filterArr)
      } else {
        if (column.property == 'contractType') {
          this.checkedContractTypeKeys = []
        }
        if (column.property == 'userFile。workDays') {
          this.checkedWorkDaysKeys = []
        }
        if (column.property == 'userFile.goodAtProductionType') {
          this.checkedGoodAtProductionTypeKeys = []
        }
        if (column.property == 'userFile.badAtProductionType') {
          this.checkedBadAtProductionTypeKeys = []
        }
        if (column.property == 'enabled') {
          this.checkedEnabledKeys = []
        }
        let filterArr = {
          contractType: [],
          workDays: [],
          goodAtProductionType: [],
          badAtProductionType: [],
          enabled: []
        }
        if (this.checkedContractTypeKeys.length) {
          filterArr.contractType = this.checkedContractTypeKeys
        }
        if (this.checkedWorkDaysKeys.length) {
          filterArr.workDays = this.checkedWorkDaysKeys
        }
        if (this.checkedGoodAtProductionTypeKeys.length) {
          filterArr.goodAtProductionType = this.checkedGoodAtProductionTypeKeys
        }
        if (this.checkedBadAtProductionTypeKeys.length) {
          filterArr.badAtProductionType = this.checkedBadAtProductionTypeKeys
        }
        if (this.checkedEnabledKeys.length) {
          filterArr.enabled = this.checkedEnabledKeys
        }
        this.tableData = JSON.parse(JSON.stringify(this.allTableData))
      }
    },
    filterQuery(filterArr) {
      let arr = []
      this.tableData = this.getFilterTableDate(JSON.parse(JSON.stringify(this.allTableData)), arr, filterArr)
    },
    getFilterTableDate(tableData, arr, filterArr) {
      for (let key in filterArr) {
        if (filterArr[key].length) {
          if (key == 'contractType') {
            tableData.forEach(i => {
              i.children?.forEach(d => {
                d.children?.forEach(p => {
                  p.children = p.children?.filter(item => filterArr[key].indexOf(item.contractType) > -1)
                  p.children?.forEach(u => {
                    if (filterArr[key]?.some(item => item == u.contractType)) {
                      if (arr.every(item => item.team != i.team)) {
                        arr.push(i)
                      }
                    }
                  })
                })
              })
            })
            arr.forEach(i => {
              i.children?.forEach(d => {
                d.children = d.children?.filter(p => p.children.length)
              })
            })
            arr.forEach(i => {
              i.children = i.children?.filter(d => d.children.length)
            })
          }
          if (key == 'enabled') {
            tableData.forEach(i => {
              i.children?.forEach(d => {
                d.children?.forEach(p => {
                  p.children = p.children?.filter(item =>
                    filterArr[key].some(f => (f == '启用' && !item.enabled) || (f == '停用' && item.enabled))
                  )
                  p.children?.forEach(u => {
                    if (filterArr[key]?.some(f => (f == '启用' && !u.enabled) || (f == '停用' && u.enabled))) {
                      if (arr.every(item => item.team != i.team)) {
                        arr.push(i)
                      }
                    }
                  })
                })
              })
            })
            if(tableData.find(item => item.team == '其他')){
              arr.push(tableData.find(item => item.team == '其他'))
            }
            arr.forEach(i => {
              if (i.children) {
                i.children?.forEach(d => {
                  d.children = d.children?.filter(p => p.children && p.children.length)
                })
              }
            })

            arr.forEach(i => {
              if (i.children) {
                i.children = i.children?.filter(d => {
                  return (
                    (d.children && d.children.length) ||
                    filterArr[key]?.some(f => (f == '启用' && !d.enabled) || (f == '停用' && d.enabled))
                  )
                })
              }
            })
          } else if (key == 'workDays') {
            tableData.forEach(i => {
              i.children?.forEach(d => {
                d.children?.forEach(p => {
                  p.children = p.children?.filter(item => filterArr[key]?.some(w => item.workDays?.some(iw => w == iw)))
                  p.children?.forEach(u => {
                    if (filterArr[key]?.some(item => u.workDays?.some(w => item == w))) {
                      if (arr.every(item => item.team != i.team)) {
                        arr.push(i)
                      }
                    }
                  })
                })
              })
            })
            arr.forEach(i => {
              i.children?.forEach(d => {
                d.children = d.children?.filter(p => p.children.length)
              })
            })
            arr.forEach(i => {
              i.children = i.children?.filter(d => d.children.length)
            })
          } else if (key == 'goodAtProductionType') {
            tableData.forEach(i => {
              i.children?.forEach(d => {
                d.children?.forEach(p => {
                  p.children = p.children?.filter(item =>
                    filterArr[key]?.some(w => item.goodAtProductionType?.some(iw => w == iw))
                  )
                  p.children?.forEach(u => {
                    if (filterArr[key]?.some(item => u.goodAtProductionType?.some(w => item == w))) {
                      if (arr.every(item => item.team != i.team)) {
                        arr.push(i)
                      }
                    }
                  })
                })
              })
            })
            arr.forEach(i => {
              i.children?.forEach(d => {
                d.children = d.children?.filter(p => p.children.length)
              })
            })
            arr.forEach(i => {
              i.children = i.children?.filter(d => d.children.length)
            })
            arr.forEach(i => {
              i.children?.forEach(d => {
                d.children?.forEach(p => {
                  p.count = p.children.length || ''
                })
              })
            })
          } else if (key == 'badAtProductionType') {
            tableData.forEach(i => {
              i.children?.forEach(d => {
                d.children?.forEach(p => {
                  p.children = p.children?.filter(item =>
                    filterArr[key]?.some(w => item.badAtProductionType?.some(iw => w == iw))
                  )
                  p.children?.forEach(u => {
                    if (filterArr[key]?.some(item => u.badAtProductionType?.some(w => item == w))) {
                      if (arr.every(item => item.team != i.team)) {
                        arr.push(i)
                      }
                    }
                  })
                })
              })
            })
            arr.forEach(i => {
              i.children?.forEach(d => {
                d.children = d.children?.filter(p => p.children.length)
              })
            })
            arr.forEach(i => {
              i.children = i.children?.filter(d => d.children.length)
            })
            arr.forEach(i => {
              i.children?.forEach(d => {
                d.children?.forEach(p => {
                  p.count = p.children.length || ''
                })
              })
            })
          }
        }
      }

      return arr
    },
    toOtherCalender(row) {
      window.open(`/system/otherTasks/${row.id}?&name=他人日历`)
    },
    checkChange(menuIndex) {
      this.copyColumns = this.columns.filter(item => {
        return item.checked
      })
      Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
        .filter((item, index) => {
          return index != 0
        })
        .forEach((item, i) => {
          try {
            if (i == menuIndex) {
              if (this.columns[i].checked) {
                item.style.display = 'table-cell'
              } else {
                item.style.display = 'none'
              }
            }
          } catch (err) {}
        })
    },
    async loadChildrenMethod({ row }) {
      // 异步加载子节点
      if (row.team == '统筹') {
        row.children?.forEach(item => {
          item.weekExpect = null
          item.weekAccept = null
          item.weekFree = null
        })
        return
      }
      if (row.expend && row.isPostion) {
        const res = await postAction(
          '/userPositionDepart/weekSchedule',
          row.children?.map(item => {
            return {
              departId: row.departCode,
              positionId: row.teamId,
              userId: item.id
            }
          })
        )
        if (res.code == 200) {
          row.children?.forEach(item => {
            res.data.forEach(i => {
              if (i.userId == item.id) {
                item.weekExpect = i.maxSchedule
                item.weekAccept = i.nowSchedule
                item.weekFree = i.count
              }
            })
          })
        }
      }
    },
    /*     treeExpand(keys) {
      this.defaultOpenKeys = keys
    }, */
    /*     treeSelect(type, selectedKeys, info) {
      console.log(selectedKeys, info)
      this.loadData()
    }, */
    toggleExpandChangeEvent({ row, expanded }) {
      this.$refs.xTree.toggleTreeExpand(row)
      if (this.$refs.xTree) this.$refs.xTree.updateData()
      return
    },
    compute(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTree.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTree.setCheckboxRow(this.selectRows, true)
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  this.$refs['statistics-' + i][0].$el.innerText = menuText + '：' + this[methodName]([], index)
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计' && i == menuIndex) {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    computeEval(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTree.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTree.setCheckboxRow(this.selectRows, true)
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  this.$refs['statistics-' + i][0].$el.innerText =
                    menuText + '：' + this[methodName]([], index, menuText)
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计') {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    sumNumEval(list, field, menuText) {
      list = this.selectRows.filter(item => item.chapterSortAndStageName)
      let type = ''
      if (menuText == '软伤') {
        type = 'SOFT'
      } else if (menuText == '硬伤') {
        type = 'HARD'
      } else if (menuText == '修改率') {
        type = 'kpiModifyRatio'
      } else if (menuText == '错翻') {
        type = 'ERROR'
      }
      console.log(list)
      let count = 0
      if (type == 'SOFT') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'SOFT') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'HARD') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'HARD') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'ERROR') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'ERROR') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'kpiModifyRatio') {
        list.forEach(item => {
          if (item.evalResult.kpiModifyRatio) {
            count = count - 0 + (item.evalResult.kpiModifyRatio - 0)
          }
        })
      }
      let num = count / list.length
      if (type == 'kpiModifyRatio') {
        return num.toFixed(2) + '%'
      }
      return num.toFixed(2) || 0
    },
    meanNum(list, field) {
      list = this.selectRows
      if (field == 'weekAvailableCount') {
        let count = 0
        list.forEach(item => {
          count += Number(item.weekTotal)
        })
        return count || 0
      }
      if (field.split('.')[0] != 'userFile') {
        let count = 0
        list.forEach(item => {
          count += Number(item[field])
        })
        return count || 0
      } else {
        let count = 0
        list.forEach(item => {
          count += Number(item.userFile[field.split('.')[1]])
        })
        return count || 0
      }
    },
    allNum(list, field) {
      return list.length
    },
    sumNum(list, field) {
      list = this.selectRows
      if (!list.length) return 0
      if (field == 'weekAvailableCount') {
        let count = 0
        list.forEach(item => {
          count += Number(item.weekTotal)
        })
        let num = count / list.length
        return num.toFixed(2) || 0
      }
      if (field.split('.')[0] != 'userFile') {
        let count = 0
        list.forEach(item => {
          count += Number(item[field])
        })
        let num = count / list.length
        return num.toFixed(2) || 0
      } else {
        let count = 0
        list.forEach(item => {
          count += Number(item.userFile[field.split('.')[1]])
        })
        let num = count / list.length
        return num.toFixed(2) || 0
      }
    },
    async cellClickEvent(e) {
      if (e.row.editable) return
      if (!e._columnIndex) return
      if (!e.row.hasChild && e._columnIndex != '1') {
        const res = await getAction('/sys/user/list', { id: e.row.id })
        if (res.code == 200) {
          this.$emit('openModal', res.data.records[0])
        } else {
          this.$message.error(res.msg)
        }
        return
      }
      if (e.row.expend) {
        e.row.expend = false
        this.$refs.xTree.setTreeExpand(e.row, false)
      } else {
        e.row.expend = true
        this.$refs.xTree.setTreeExpand(e.row, true)
      }
      if (this.$refs.xTree) this.$refs.xTree.updateData()
      return
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (this.propertys.includes(column.property)) {
            return parseInt(this[this.methodName](data, column.property))
          }
          if (columnIndex == 0) {
            return '已选择：' + this.selectRows.filter(item => !item.hasChild).length + '项'
          }
          return ''
        })
      ]
    },
    uniqueFunc(arr, uniId) {
      const res = new Map()
      return arr.filter(item => !res.has(item[uniId]) && res.set(item[uniId], 1))
    },
    /* loadData: simpleDebounce(async function() {
      this.getTableDataSim()
    }, 200),
    getTableData: simpleDebounce(async function() {
      this.getTableDataSim()
    }, 200), */
    async loadData() {
      this.tableLoading = true
      this.queryParmas = {
        current: 1,
        size: -1
      }
      if (this.queryParamProp.nickName) this.queryParmas.nickName = '*' + this.queryParamProp.nickName + '*'
      /* const res = await getAction(this.url.list, this.queryParmas) */
      const res = await getAction('/sys/user/list/v2')
      if (res.code == 200 || res) {
        this.clearSelectKeys()
        this.tableData = []
        this.allTableData = []
        /*           res.data.records = res.data.records.sort((a, b) => a.nickName.localeCompare(b.nickName))
          this.treeData = res.data.records
          res.data.records.forEach(item => {
            if (
              this.tableData.every(i => {
                return item.departs.every(d => d.departName != i.team)
              })
            ) {
              item.departs.forEach(d => {
                this.tableData.push({
                  team: d.departName,
                  isDepart: true,
                  hasChild: true,
                  isPostion: false,
                  teamId: d.id
                })
              })
            }
          })  */
        /* res.data = res.data.length && res.data[0].lowers */
        res.data = res && res[0].lowers
        let other = res.filter((item, index) => index)
        res.data?.forEach(i => {
          i.value = i.id
          i.team = i.name
          i.teamId = i.id
          i.type = i.type
          i.children = i.lowers
          i.hasChild = true
          i.scopedSlots = { title: 'title' }
          i.children?.forEach(d => {
            d.value = d.id
            d.team = d.name
            d.teamId = d.id
            d.hasChild = true
            d.type = d.type
            d.children = d.lowers
            d.scopedSlots = { title: 'title' }
            d.children?.forEach(p => {
              p.value = p.id
              p.team = p.name
              p.teamId = p.id
              p.type = p.type
              p.hasChild = true
              p.isPostion = true
              p.children = p.lowers
              p.scopedSlots = { title: 'title' }
              p.children?.forEach(u => {
                u.value = u.id
                u.team = u.name
                u.teamId = u.id
                u.hasChild = false
                u.type = u.type
                u.scopedSlots = { title: 'title' }
              })
            })
          })
        })
        if (this.isAdmin) {
          res.data.push({
            value: 'other',
            team: '其他',
            teamId: 'other',
            children: other,
            hasChild: true,
            isPostion: true
          })
        }
        this.tableData = res.data
        this.allTableData = JSON.parse(JSON.stringify(this.tableData))
        this.tableLoading = false
        if (this.$refs.xTree) {
          this.$refs.xTree.updateData()
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, index) => {
              if (item.childNodes[0]) {
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + index][0].$el)
              }
            })
        }
        setTimeout(() => {
          this.tableData.forEach(row => {
            if (row.team != '其他') {
              this.$refs.xTree.setTreeExpand(row, true)
            }
          })
        }, 0)
        /*           setTimeout(() => {
            if (this.queryParamProp.nickName) {
              this.$refs.xTree.setAllTreeExpand(true)
            }
          }, 300) */
      }
    },
    searchQuery() {
      if (!this.queryParamProp.nickName) {
        this.tableData = JSON.parse(JSON.stringify(this.allTableData))
        return
      }
      let arr = []
      this.tableData = this.getSaerchTableDate(JSON.parse(JSON.stringify(this.allTableData)), arr)
      this.tableData.forEach(item => {
        item.children?.forEach(i => {
          i.children?.forEach(async row => {
            const res = await postAction(
              '/userPositionDepart/weekSchedule',
              row.children?.map(item => {
                return {
                  departId: row.departCode,
                  positionId: row.teamId,
                  userId: item.id
                }
              })
            )
            if (res.code == 200) {
              row.children?.forEach(item => {
                res.data.forEach(i => {
                  if (i.userId == item.id) {
                    item.weekExpect = i.maxSchedule
                    item.weekAccept = i.nowSchedule
                    item.weekFree = i.count
                  }
                })
              })
            }
          })
        })
      })
      this.$nextTick(() => {
        if (this.queryParamProp.nickName) {
          this.$refs.xTree.clearTreeExpand()
          this.$refs.xTree.setAllTreeExpand(true)
        }
      })
    },
    getSaerchTableDate(tableData, arr) {
      tableData.forEach(i => {
        i.children?.forEach(d => {
          d.children?.forEach(p => {
            p.children = p.children?.filter(item => item.team.toLowerCase().indexOf(this.queryParamProp.nickName.toLowerCase()) > -1)
            p.children?.forEach(u => {
              if (u.team.toLowerCase().indexOf(this.queryParamProp.nickName.toLowerCase()) > -1) {
                if (arr.every(item => item.team != i.team)) {
                  arr.push(i)
                }
              }
            })
          })
        })
      })
      arr.forEach(i => {
        i.children?.forEach(d => {
          d.children = d.children?.filter(p => p.children.length)
        })
      })
      arr.forEach(i => {
        i.children = i.children?.filter(d => d.children.length)
      })
      return arr
    },
    clearSelectKeys() {
      this.$emit('setRowkeys', [])
      if (this.$refs.xTree) this.$refs.xTree.clearCheckboxRow()
      this.selectRows = []
      if (this.$refs.xTree) this.$refs.xTree.updateFooter() //手动更新表尾
    },
    selectChangeEvent() {
      const records = this.$refs.xTree.getCheckboxRecords()
      this.$emit(
        'setRowkeys',
        records.filter(item => !item.hasChild)
      )
      this.selectRows = records.filter(item => !item.hasChild)
      this.$refs.xTree.updateFooter() //手动更新表尾
    },
    getWorkCity(data) {
      let text = ''
      if (data) {
        data = data.split(',')
        citys.options.forEach(item => {
          if (item.value == data[data.length - 1]) {
            text = item.label
            return text
          }
          item.children?.forEach(i => {
            if (i.value == data[data.length - 1]) {
              text = i.label
              return text
            }
            i.children?.forEach(e => {
              if (e.value == data[data.length - 1]) {
                text = i.label + '/' + e.label
                return text
              }
            })
          })
        })
      }
      return text
    }
  }
}
</script>

<style lang="less" scoped>
/* /deep/ .vxe-table--fixed-wrapper .vxe-table--footer-wrapper{
    display: none !important;
  } */
.nick-name-div:hover {
  color: var(--theme-color);
  cursor: pointer;
  img {
    border: 1px solid var(--theme-color);
  }
}
/deep/ .vxe-table--render-default .vxe-table--fixed-right-wrapper {
  box-shadow: none !important;
}
/deep/ .vxe-filter--btn {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 16px;
  position: relative;
  top: -2px;
}
/deep/ .vxe-filter--btn:hover {
  color: var(--theme-color) !important;
}
/deep/ .filter-contractType-btn {
  color: var(--contractType-color) !important;
}
/deep/ .filter-workDays-btn {
  color: var(--workDays-color) !important;
}
/deep/ .filter-goodAtProductionType-btn {
  color: var(--goodAtProductionType-color) !important;
}
/deep/ .filter-badAtProductionType-btn {
  color: var(--badAtProductionType-color) !important;
}
/deep/ .filter-enabled-btn {
  color: var(--enabled-color) !important;
}
.tree-page {
  position: absolute;
  width: 190px;
  left: -202px;
  top: 12px;
}
/deep/ .vxe-table--body-wrapper {
  max-height: calc(100vh - 335px) !important;
}
/* /deep/ .vxe-table--fixed-left-wrapper {
    .vxe-table--body-wrapper{
      max-height: var(--table-height) !important;
    }
  } */
.review-product:hover {
  color: var(--theme-color);
  cursor: pointer;
}
.anticon-check-circle {
  color: var(--theme-color);
}
.statistics {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
  &:hover {
    background: var(--back-color);
  }
}
.icon-list {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
  top: 12px;
  i {
    &:hover {
      cursor: pointer;
    }
  }
}
.statistics-show {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
}
.statistics-menu-list {
  opacity: 0;
  height: 0;
}
/deep/ .vxe-footer--row {
  .vxe-cell--item {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .vxe-footer--column {
    &:first-child {
      .vxe-cell--item {
        opacity: 1;
        width: 120px;
      }
    }
  }
}
/deep/ .vxe-cell--sort {
  font-size: 12px !important;
  width: 1em !important;
  height: 1.35em !important;
  margin-right: 7px;
}
/deep/ .vxe-cell--filter {
  font-size: 12px !important;
  margin-top: 2px;
}
/deep/ .vxe-header--row {
  .vxe-cell {
    display: flex;
    align-items: center;
    .vxe-cell--title {
      flex: 1;
    }
  }
}
.status-img {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  .status {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
}
/deep/ .is--indeterminate .vxe-checkbox--icon::before {
  background-color: #fff !important;
}
/deep/ .vxe-checkbox--icon:hover {
  &::before {
    border-color: var(--theme-color) !important;
  }
}
/deep/ .is--checked .vxe-icon-checkbox-checked {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon {
  font-weight: 400 !important;
}
/deep/ .vxe-table--render-default .vxe-body--row.row--current {
  background-color: var(--back-color) !important;
}
/deep/ .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--border-line {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-top-width: 0 !important;
}
/deep/ .is--filter-active .vxe-cell--filter .vxe-filter--btn {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .is--checked {
  color: var(--theme-color) !important;
}
/deep/ .is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-option:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer button:hover {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer {
  display: flex;
  justify-content: space-evenly;
  button {
    &:hover {
      color: var(--theme-color);
    }
  }
}
/* /deep/ .fixed-right--wrapper .col_3{
    opacity: 0 !important;
  } */
/deep/ .vxe-table--filter-wrapper {
  z-index: 9999;
}
/deep/ .is--indeterminate.vxe-table--filter-option {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--body-wrapper {
  .vxe-body--x-space {
    display: none;
  }
}
/deep/ .vxe-table--render-default .fixed--hidden {
  visibility: unset !important;
}
/deep/ .vxe-table--fixed-wrapper .vxe-body--row:last-child .vxe-cell {
  height: auto !important;
}
.vxe-table--fixed-left-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/deep/ .vxe-body--column {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-table--footer-wrapper {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-header--row {
  background-color: #f8f8f9;
}
/deep/ .vxe-header--row .vxe-cell {
  font-weight: 400 !important;
}
</style>
